export const StaffRouters = [
    
    {
        path: "/warehouse-rank",
        value: "menu.warehouseRank",
    },
    {
        path:'/staff/goods-list',
        value:'menu.goods'
    },
    {
        path:'/staff/register-goods',
        value:'menu.register'
    },
    {
        path:'/staff/left-goods',
        value:'menu.leftChina'
    },
    {
        path:'/staff/arrived-goods',
        value:'menu.arrived'
    },
]